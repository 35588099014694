import { graphql, useStaticQuery } from 'gatsby';

import Layout from 'layouts/default';

import CustomerFeatureBenefit from 'components/content-blocks/customer-feature-benefit';
import QuoteBox from 'components/content-blocks/customer-quote-box';
import FeaturesGrid from 'components/content-blocks/features-grid';
import Highlight from 'components/content-blocks/highlight';
import MediaObject from 'components/content-blocks/media-object';
import MobileFeatures from 'components/content-blocks/mobile-features';
import StructuredData from 'components/content-blocks/partials/structured-data';
import Products from 'components/content-blocks/products';
import Reviews from 'components/content-blocks/reviews';
import MoreIndustries from 'components/globals/more-industries';
import Yoast from 'components/globals/yoast';
import { HeroFeatures } from 'components/hero/features';

import './styles/team-messaging.scss';

export default function TeamMessaging() {
  const data = useStaticQuery(graphql`
    {
      allWordpressPage(filter: { slug: { eq: "team-messaging" } }) {
        edges {
          node {
            wordpress_id
        yoast_head_json {
          title
          description
          canonical
          og_title
          og_description
          og_image {
            url {
              source_url
            }
          }
          og_url
          og_type
          twitter_title
          twitter_description
          twitter_image {
            source_url
          }
        }
            acf {
              hero {
                title
                sub_title
                copy
                signup {
                  unique_id
                  modal_title
                  legal_text
                  modal_submit_button_text
                  launch_modal_button_text
                  footer_signup_title
                }
                background_desktop_image {
                  source_url
                  alt_text
                }
              }
              cards_1 {
                header {
                  title
                  sub_title
                }
                card {
                  header
                  image {
                    source_url
                  }
                  title
                  copy
                  link_url
                  link_text
                  is_active
                }
              }
              cards_2 {
                header {
                  title
                  sub_title
                }
                card {
                  header
                  image {
                    source_url
                  }
                  title
                  copy
                  link_url
                  link_text
                  is_active
                }
              }
              faq {
                question
                answer
              }
              features_grid_mobile_1 {
                title
                sub_title
                buttons {
                  button_1 {
                    icon_from_library
                    icon_width
                    title
                    body
                    desktop_screenshot {
                      source_url
                    }
                    mobile_screenshot {
                      source_url
                    }
                  }
                  button_2 {
                    icon_from_library
                    icon_width
                    title
                    body
                    desktop_screenshot {
                      source_url
                    }
                    mobile_screenshot {
                      source_url
                    }
                  }
                  button_3 {
                    icon_from_library
                    icon_width
                    title
                    body
                    desktop_screenshot {
                      source_url
                    }
                    mobile_screenshot {
                      source_url
                    }
                  }
                  button_4 {
                    icon_from_library
                    icon_width
                    title
                    body
                    desktop_screenshot {
                      source_url
                    }
                    mobile_screenshot {
                      source_url
                    }
                  }
                }
              }
              features_grid {
                title
                sub_title
                buttons {
                  title
                  body
                  alt_text
                  icon_width
                  icon_from_library
                  mobile_screenshot {
                    source_url
                  }
                  desktop_screenshot {
                    source_url
                  }
                }
              }
              customer_feature_benefit {
                sub_title
                title
                copy
                customer {
                  business
                  name
                  photo_desktop {
                    source_url
                  }
                  photo_mobile {
                    source_url
                  }
                  since
                }
                stats {
                  value_1
                  descriptor_1
                  value_2
                  descriptor_2
                  value_3
                  descriptor_3
                }
              }
              customer_quote_1 {
                company_info
                desktop_image {
                  source_url
                }
                mobile_image {
                  source_url
                }
                quote
              }
              media_object {
                body
                sub_title
                title
                button_url
                button_text
                desktop_image {
                  alt_text
                  source_url
                }
                mobile_image {
                  alt_text
                  source_url
                }
                wysiwyg
              }
              reviews_section {
                copy
                sub_title
                title
              }
            }
          }
        }
      }
      allWordpressAcfOptions {
        edges {
          node {
            options {
              structured_data {
                product_team_messaging
                faq {
                  team_messaging
                }
              }
              industries {
                industries {
                  url
                  title
                  icon {
                    alt_text
                    source_url
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const pageContext = data.allWordpressPage.edges[0].node.acf;
  const hero = pageContext.hero;
  const mediaObject = pageContext.media_object;
  const cards1 = pageContext.cards_1;
  const cards2 = pageContext.cards_2;
  const faq = pageContext.faq;
  const faqStructuredData = data.allWordpressAcfOptions.edges[0].node.options.structured_data.faq.team_messaging;
  const customerQuote = pageContext.customer_quote_1;
  const customerCard = pageContext.customer_feature_benefit;
  const mobileFeatures = pageContext.features_grid_mobile_1;
  const featuresGrid = pageContext.features_grid;
  const moreIndustries = data.allWordpressAcfOptions.edges[0].node.options.industries;
  const structuredData = data.allWordpressAcfOptions.edges[0].node.options.structured_data.product_team_messaging;
  
  return (
    <Layout
      faq={ faq }
      footerCtaTitle={ pageContext.hero.signup.footer_signup_title }
      modalTitle={ pageContext.hero.signup.modal_title }
      legalText={ hero.signup.legal_text }
      faqStructuredData={ faqStructuredData }
    >
      <Yoast { ...data.allWordpressPage.edges[0].node.yoast_head_json } />
      <div className="features-team-messaging-page-container" data-signal={ data.allWordpressPage.edges[0].node.wordpress_id }>
        <HeroFeatures
          { ...hero }
          uniqueId="teamFeature"
          section="featuresTeamMessagingHero"
        />
        <Highlight { ...cards1 } hasImage hasTitle titleAsHeader />
        <MediaObject { ...mediaObject[0] } className="features-team-messaging" />
        <MediaObject { ...mediaObject[2] } className="reverse more-padding" />
        <CustomerFeatureBenefit { ...customerCard[0] } />
        <MobileFeatures { ...mobileFeatures } />
        <QuoteBox { ...customerQuote } />
        <FeaturesGrid { ...featuresGrid[0] } />
        <MediaObject { ...mediaObject[1] } className="features-payroll reverse" />
        <Reviews { ...pageContext.reviews_section } />
        <MoreIndustries { ...moreIndustries } />
        <Products 
          { ...cards2 } 
          modalTitle={ hero.modal_title }
          hasHeadline           
          activeIndex={ 0 } 
        />
      </div>
      <StructuredData data={ structuredData } />
    </Layout>
  );
}
